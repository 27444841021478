<template>
  <div style="background-color: white">
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <nu-modal ref="confirmmodal12">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2rem;
                    color: black;
                  "
                >
                  Reservation Form
                </h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Ticket Title:</b
                >
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <!-- <p
                v-if="!redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Per Person Price:</b
                >
                ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p> -->
              <!-- <p v-if="redeemData">
                <b
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Per Person Price:</b
                >
                ${{ redeemData.price.toFixed(2) }}
              </p> -->
              <p
                v-if="!redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Total Price:
                </b>
                ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p
                v-if="redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Total Price:
                </b>
                ${{ ((redeemData.price || 0) * tickcount).toFixed(2) }}
              </p>
            </div>
            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Full Name</label
              >
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Enter your full name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Email Address</label
              >

              <input
                type="email"
                class="form-control mb-3"
                placeholder="Enter your email address"
                v-model="email"
                required
              />
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                TicketsResult[selected] &&
                (TicketsResult[selected].ticketCode == 'tk_rZSBy6XYB0mi1gD' ||
                  TicketsResult[selected].ticketCode == 'tk_6nrKeDNABJpl2Z8' ||
                  TicketsResult[selected].ticketCode == 'tk_c50x9bsGTpPFcUO' ||
                  TicketsResult[selected].ticketCode == 'tk_VCPLvbavEi9uAok')
              "
            >
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Section</label
              >
              <select
                class="form-control"
                v-model="selectedSection"
                @change="sectionChange"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in listSections()"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                TicketsResult[selected] &&
                (TicketsResult[selected].ticketCode == 'tk_rZSBy6XYB0mi1gD' ||
                  TicketsResult[selected].ticketCode == 'tk_6nrKeDNABJpl2Z8' ||
                  TicketsResult[selected].ticketCode == 'tk_c50x9bsGTpPFcUO' ||
                  TicketsResult[selected].ticketCode == 'tk_VCPLvbavEi9uAok')
              "
            >
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Row</label
              >
              <select
                class="form-control"
                v-model="selectedRow"
                @change="rowChange"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in listRows()"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                TicketsResult[selected] &&
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                (TicketsResult[selected] || {}).ticketCode !=
                  'tk_rZSBy6XYB0mi1gD' &&
                (TicketsResult[selected] || {}).ticketCode !=
                  'tk_6nrKeDNABJpl2Z8' &&
                (TicketsResult[selected] || {}).ticketCode !=
                  'tk_c50x9bsGTpPFcUO' &&
                (TicketsResult[selected] || {}).ticketCode !=
                  'tk_VCPLvbavEi9uAok'
              "
            >
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Available Table(s)</label
              >
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-12 mt-2"
              v-if="
                seatsOptions.length <= 0 &&
                TicketsResult[selected] &&
                (TicketsResult[selected].ticketCode == 'tk_rZSBy6XYB0mi1gD' ||
                  TicketsResult[selected].ticketCode == 'tk_6nrKeDNABJpl2Z8' ||
                  TicketsResult[selected].ticketCode == 'tk_c50x9bsGTpPFcUO' ||
                  TicketsResult[selected].ticketCode == 'tk_VCPLvbavEi9uAok')
              "
            >
              <doublelist
                @listtopush="listtopush"
                :list="[]"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div
              class="col-md-12 mt-2"
              v-if="
                seatsOptions.length > 0 &&
                TicketsResult[selected] &&
                (TicketsResult[selected].ticketCode == 'tk_rZSBy6XYB0mi1gD' ||
                  TicketsResult[selected].ticketCode == 'tk_6nrKeDNABJpl2Z8' ||
                  TicketsResult[selected].ticketCode == 'tk_c50x9bsGTpPFcUO' ||
                  TicketsResult[selected].ticketCode == 'tk_VCPLvbavEi9uAok')
              "
            >
              <doublelist
                @listtopush="listtopush"
                :list="[...seatsOptions]"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div
              class="col-md-12 mt-4"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.15em;
                color: black;
              "
            >
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !reserveFormAccept ||
                  ((TicketsResult[selected] &&
                    TicketsResult[selected].ticketCode ==
                      'tk_rZSBy6XYB0mi1gD') ||
                  TicketsResult[selected].ticketCode == 'tk_6nrKeDNABJpl2Z8' ||
                  TicketsResult[selected].ticketCode == 'tk_c50x9bsGTpPFcUO' ||
                  TicketsResult[selected].ticketCode == 'tk_VCPLvbavEi9uAok'
                    ? selectionList.length < 1
                    : false)
                "
                class="btn solid-btn-tuskegee m-auto mr-2"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <!-- <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div> -->
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_TU100thHomecomingAlumniConcertFundraiser_V5.jpg"
            style="width: 100%; max-width: 100%; height: auto"
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #a32035; border-top: 1px solid #eba900"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  color: #eba900;
                "
              >
                TU 100th Homecoming Alumni Concert & Fundraiser starring KEM
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2rem;
                  color: #eba900;
                "
              >
                Friday, November 08, 2024, <br />
                3:30 PM - 7:30 PM
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a
                  href="https://maps.app.goo.gl/c5ZsQqCKLCap2vpt5"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: #eba900"
                >
                  Chappie James Arena
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.4rem;
                  color: black;
                "
              >
                Tuskegee University Office of Alumni Affairs Homecoming Concert
                will feature Kem, with comedian Special K and special guest Beth
                Manley on November 8.

                <br /><br />

                Doors open at 3:30 PM<br />
                Show begins at 4:30 PM

                <br /><br />

                The concert is a fundraiser for the University Endowment. Tables
                include two bottles of wine and a snack tray.

                <br /><br />

                All tickets include a processing fee and taxes via MYLE.
              </p>

              <br />

              <p
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.8rem;
                  color: black;
                "
              >
                Disclaimer:
              </p>

              <p
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.4rem;
                  color: black;
                "
              >
                As a ticket purchaser, you acknowledge that the Tuskegee
                University Office of Alumni Affairs is not responsible for any
                tickets that are stolen, lost, or misplaced. Once purchased,
                tickets are non-refundable.

                <br /><br />

                On the day of the concert, all ticket holders MUST present
                either printed tickets, tickets on the MYLE app or via
                electronic ticket to enter the venue. Tickets are immediately
                issued to the purchasers email account used during the ticket
                purchase. Check your email inbox or spam folder after purchase
                to confirm receipt.

                <br /><br />

                To easily access your tickets on the MYLE App, download the app
                at <a target="_blank" href="https://myle.com/">MYLE.com</a> or
                via your app store under MYLE Events. Tickets are located in the
                account > tickets section and may be added to your phone wallet
                for easy access.

                <br /><br />

                The Tuskegee University Office of Alumni Affairs will use
                real-time scanners to prevent fraudulent or duplicate tickets at
                the Homecoming Concert.

                <br /><br />

                It is our top priority to offer a premier experience for our
                alumni!

                <br /><br />
                For any questions, please contact the Office of Alumni Affairs
                at <a href="tel:+13347278521"> 334-727-8521</a> or via email at
                <a href="mailto:tualumni@tuskegee.edu"> tualumni@tuskegee.edu</a
                >.

                <br /><br />

                By attending the concert, you are supporting Tuskegee University
                and its endowment. A portion of your ticket price is
                tax-deductible.
              </p>

              <div class="section-heading mt-1">
                <div class="text-center">
                  <a
                    href="https://www.tuskegee.edu/homecoming"
                    class="btn solid-btn-tuskegee text-center"
                    target="_blank"
                    rel="noopener noreferrer"
                    style="
                      pointer-events: all;
                      cursor: pointer;
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 1.2em;
                      font-weight: bold;
                    "
                    >TU Homecoming Page
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section endss -->
      <br />
      <br />
      <p
        class="text-center"
        style="
          font-family: 'nunito', sans-serif;
          font-weight: 700;
          font-size: 2.5em;
          background: #a32035;
          color: #eba900;
        "
      >
        Seating Map
      </p>

      <div
        id="d1"
        style="
          max-width: 1400px;
          height: 2000px;
          margin: auto;
          height: auto;
          margin-top: 50px;
        "
      ></div>

      <div
        class="row align-items-center justify-content-center test"
        style="margin: auto; margin-top: 20px"
      >
        <div
          class="col-md-2 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #ff0000;
                      color: #ff0000;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 600;
                      font-size: 0.9em;
                    "
                  >
                    Early Bird Seating
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-2 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #ff92ff;
                      color: #ff92ff;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 600;
                      font-size: 0.9em;
                    "
                  >
                    Preferred Seating
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-2 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #73c2fb;
                      color: #73c2fb;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 600;
                      font-size: 0.9em;
                    "
                  >
                    Premium Seating
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-2 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #4fff00;
                      color: #4fff00;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 600;
                      font-size: 0.9em;
                    "
                  >
                    VIP Tables
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-2 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: lightgrey;
                      color: lightgrey;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 600;
                      font-size: 0.9em;
                    "
                  >
                    Reserved
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center mt-5">
        <div class="col-md-12">
          <div class="py-5">
            <div
              class="text-center"
              style="background: #a32035; color: #eba900"
            >
              <h3
                class="w-auto d-inline-block pt-3 pb-0"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 2.5em;
                  background: #a32035;
                  color: #eba900;
                "
              >
                TICKET OPTIONS
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center mt-5">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:class="{
                  cred: obj.ticketCode == 'tk_rZSBy6XYB0mi1gD',
                  credg: obj.ticketCode == 'tk_6nrKeDNABJpl2Z8',
                  cmagenta: obj.ticketCode == 'tk_c50x9bsGTpPFcUO',
                  cblue: obj.ticketCode == 'tk_VCPLvbavEi9uAok',
                  cgreen: obj.ticketCode == 'tk_UOsXamYXUXquI5n',
                }"
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div
                  class="card-header text-center"
                  style="
                    background: #a32035;
                    color: #eba900;
                    width: 310px !important;
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.12em;
                  "
                >
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    style="
                      color: #000000;
                      font-family: 'nunito', sans-serif;
                      font-weight: 500;
                      font-size: 1.1em;
                    "
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    ‣ {{ msgs }}
                  </p>
                  <div
                    class="text-center"
                    style="
                      color: #000000;
                      font-family: 'nunito', sans-serif;
                      font-weight: 800;
                      font-size: 1.3em;
                      margin-top: auto;
                    "
                  >
                    <p>Total Price: ${{ obj.price.toFixed(2) }}</p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn-tuskegee shimmer"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-family: 'nunito', sans-serif;
                        font-weight: 700;
                        font-size: 1em;
                        font-weight: bold;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div
      class="row w-100 m-0 promo-section MYLE-lite-green"
      style="background: #170200"
    >
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center" style="color: #dab785">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <section class="container align-items-center text-center">
      <div class="row align-items-center">
        <div class="col-md-3 col-lg-3 align-items-center"></div>

        <div class="col-md-6 col-lg-6 align-items-center">
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo_TU.png"
              alt="Saturday Event"
              class="w-75 img-fluid align-items-center"
            />
          </div>
        </div>
        <div class="col-md-3 col-lg-3 align-items-center"></div>
      </div>
    </section>

    <div class="row w-100 promo-section m-0">
      <div class="col-lg-12 col-md-12">
        <div class="">
          <div class="section-heading">
            <h2
              class="text-center"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
              "
            >
              Powered by <a target="_blank" href="https://myle.com/">MYLE</a>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <br />

    <!-- <site-footer :show-subscribe="true" /> -->
  </div>
</template>

<script>
// Component Imports
// import NavBar from "../../views/commons/NavBar";
// import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import Doublelist from "./doubleList.vue";
import confirmationPopup from "../../components/confirmationPopup";
// defining component state
export default {
  name: "newyear",
  data() {
    return {
      seatsOptions: [],
      mapper: {},
      selectedRow: "",
      selectedSection: "",
      showsuccess: false,
      options: {
        text: {
          color: "black",
          fontSize: 10,
          textAdjustmentY: 2,
          fontFamily: "Arial",
          textEnabled: true,
        },
        thermo: {
          color: "#145143",
          backgroundColor: "#ffffff",
          frameColor: "black",
          ticks: 10,
          ticksEnabled: true,
          tickColor: "black",
          tickWidth: "1",
        },
        layout: {
          height: 300,
          width: 90,
        },
      },
      selectionList: [],
      dict: {},
      dictlist: {},
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      availSeating: "",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: [
        "tk_rZSBy6XYB0mi1gD",
        "tk_6nrKeDNABJpl2Z8",
        "tk_c50x9bsGTpPFcUO",
        "tk_VCPLvbavEi9uAok",
        "tk_UOsXamYXUXquI5n",
      ],

      groupData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_rZSBy6XYB0mi1gD: {
          messages: [
            "Ticket Price: $100",
            "Processing fee of $8.35",
            "Sales Tax of $6.5",
          ],
        },

        tk_6nrKeDNABJpl2Z8: {
          messages: [
            "Ticket Price: $115",
            "Processing fee of $9.36",
            "Sales Tax of $7.46",
          ],
        },

        tk_c50x9bsGTpPFcUO: {
          messages: [
            "Ticket Price: $140",
            "Processing fee of $11.03",
            "Sales Tax of $9.06",
          ],
        },

        tk_VCPLvbavEi9uAok: {
          messages: [
            "Ticket Price: $170",
            "Processing fee of $13.04",
            "Sales Tax of $10.98",
          ],
        },

        tk_UOsXamYXUXquI5n: {
          messages: [
            "Ticket Price: $1600",
            "Processing fee of $108.95",
            "Sales Tax of $102.54",
          ],
        },
      },

      priceIds: {
        tk_rZSBy6XYB0mi1gD: "price_1Q8p8pD0vTZ4QB9c73Gpu0N7",
        tk_6nrKeDNABJpl2Z8: "price_1Q8p98D0vTZ4QB9cOnN6a9Ju",
        tk_c50x9bsGTpPFcUO: "price_1Q8p9ED0vTZ4QB9copDSd0GX",
        tk_VCPLvbavEi9uAok: "price_1Q8p9KD0vTZ4QB9cxII6noWS",
        tk_UOsXamYXUXquI5n: "price_1Q8p9QD0vTZ4QB9cjs71aldv",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      bname: "",
      email: "",
      phoneNo: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    // NavBar,
    // SiteFooter,
    VueElementLoading,
    Doublelist,
    StripeCheckout,
    confirmationPopup,
  },
  methods: {
    sectionChange() {
      this.selectedRow = "";
      this.seatsOptions = [];
    },
    rowChange() {
      this.seatsOptions = [];
      this.seatsOptions = JSON.parse(
        JSON.stringify([...this.mapper[this.selectedSection][this.selectedRow]])
      );
    },
    listSections() {
      return Object.keys(this.mapper || {});
    },
    listRows() {
      if (!this.selectedSection) {
        return [];
      }
      return Object.keys(this.mapper[this.selectedSection] || {});
    },
    datad2(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const zx = [];
      const rowss = [
        "X",
        "W",
        "V",
        "U",
        "T",
        "S",
        "R",
        "Q",
        "P",
        "O",
        "N",
        "M",
        "L",
        "K",
        "J",
        "H",
        "G",
        "F",
        "E",
      ];
      const rowss1 = ["L", "K", "J", "H", "G", "F", "E"];
      //  const rowss2 = ['D', 'C', 'B', 'A','G', 'F', 'E']

      // red top 200 seats starts
      let x = 0;
      let xy = 15;
      for (let j = 0; j < 12; j++) {
        x = 350;
        for (let i = 0; i < 56; i++) {
          if ([7, 21, 35, 49].includes(i)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: rowss[j],
              y: j * 15 + xy + 1,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 15;
          }

          zx.push({
            x: (i + 1) * 15 + x,
            width: 15,
            height: 15,
            text: i + 1,
            id: "Section 200 - Row " + rowss[j] + " - Seat " + (i + 1),
            y: j * 15 + xy,
            type: "rect",
            fill: "red",
            stroke: "white",
          });
        }
      }

      x = 350;
      for (let i = 0; i < 58; i++) {
        if ([29, 40, 51].includes(i)) {
          zx.push({
            x: (i + 1) * 15 + x,
            width: 15,
            height: 15,
            text: "Y",
            y: 0,
            type: "rect",
            fill: "white",
            stroke: "white",
          });
          x += 15;
        }
        if (
          ![
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
          ].includes(i + 1)
        ) {
          zx.push({
            x: (i + 1) * 15 + x,
            width: 15,
            height: 15,
            text: i + 1,
            y: 0,
            id: "Section 200 - Row Y - Seat " + (i + 1),
            type: "rect",
            fill: "red",
            stroke: "white",
          });
        }
      }
      // red top 200 seats ends

      // red left 100 seats starts
      let skips = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        ["A", "B", 29, 30],
        ["A", "B", 29, 30],
        ["A", "B", 29, 30],
        ["A", "B", 29, 30],
        ["A", "B", 29, 30, 1, 28],
        ["A", "B", 29, 30, 1, 28],
        ["A", "B", 29, 30, 1, 28],
        ["A", "B", 29, 30, 1, 28],
        ["A", "B", 29, 30, 1, 28],
      ];
      x = 100;
      xy = 320;
      let tsts = [
        30,
        29,
        28,
        27,
        26,
        25,
        24,
        23,
        22,
        21,
        20,
        19,
        18,
        17,
        16,
        15,
        14,
        13,
        12,
        11,
        10,
        9,
        8,
        7,
        6,
        5,
        4,
        3,
        2,
        1,
        "A",
        "B",
      ];
      let rowdt = [
        "E",
        "F",
        "G",
        "H",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
      ];
      for (let j = 0; j < 20; j++) {
        x = 0;
        for (let i = 0; i < 32; i++) {
          if (rowdt[19 - j] != "I") {
            if (["A", 14, 28, 30].includes(tsts[i])) {
              zx.push({
                y: (i + 1) * 15 + x + xy,
                width: 15,
                height: 15,
                text: rowdt[19 - j],
                x: j * 15,
                type: "rect",
                fill: "white",
                stroke: "white",
              });
              x += 20;
            }
            if (!skips[j].includes(tsts[i])) {
              zx.push({
                y: (i + 1) * 15 + x + xy,
                width: 15,
                height: 15,
                text: tsts[i],
                x: j * 15,
                id: "Section 100 - Row " + rowdt[19 - j] + " - Seat " + tsts[i],
                type: "rect",
                fill: "#FF0000",
                stroke: "white",
              });
            }
          }
        }
      }
      // red left 100 seats ends

      // cyan left 100 seats starts
      skips = [
        [1, 2, 3, 4, 5, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        [1, 2, 3, 4, 5, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          28, 29, 30, 31, 32,
        ],
        [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          28, 29, 30, 31, 32,
        ],
      ];
      x = 300;
      xy = 370;
      tsts = [
        30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13,
        12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1,
      ];
      rowdt = ["D", "C", "B", "A"];
      for (let j = 0; j < 4; j++) {
        x = 0;
        for (let i = 0; i < 30; i++) {
          if ([9, 19].includes(tsts[i])) {
            zx.push({
              y: (i + 1) * 15 + x + xy,
              width: 15,
              height: 15,
              text: rowdt[j],
              x: j * 15 + 320,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 20;
          }
          if (!skips[j].includes(tsts[i])) {
            zx.push({
              y: (i + 1) * 15 + x + xy,
              width: 15,
              height: 15,
              text: tsts[i],
              x: j * 15 + 320,
              id: "Section 100 - Row " + rowdt[j] + " - Seat " + tsts[i],
              type: "rect",
              fill: "#73c2fb",
              stroke: "white",
            });
          }
        }
      }
      // red left 100 seats ends

      // cyan / magenta Top 200 starts
      x = 300;
      xy = 200;
      let skip = [
        [1, 2, 3],
        [1, 2, 3, 54, 55, 56],
        [1, 2, 3, 4, 53, 54, 55, 56],
        [1, 2, 3, 4, 5, 6, 7, 8, 53, 54, 55, 56],
        [1, 2, 3, 4, 5, 6, 7, 8, 52, 53, 54, 55, 56],
        [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 50, 51, 52, 53, 54, 55, 56,
        ],
        [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 50, 51, 52, 53, 54, 55, 56, 36, 37, 38, 39, 40, 41, 42, 43,
          44, 45, 46, 47, 48, 49,
        ],
      ];
      for (let j = 0; j < 7; j++) {
        x = 330;
        for (let i = 0; i < 56; i++) {
          if ([8, 21, 35, 49].includes(i)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: rowss1[j],
              y: j * 15 + xy,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 15;
          }
          if (!skip[j].includes(i + 1)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: i + 1,
              y: j * 15 + xy,
              type: "rect",
              id: "Section 200 - Row " + rowss1[j] + " - Seat " + (i + 1),
              fill: i + 1 > 21 ? "#ff92ff" : "#73c2fb",
              stroke: "white",
            });
          }
        }
      }
      // cyan / magenta Top 200 ends

      // cyan Top 200 200 seats starts
      xy = 320;
      skip = [
        [1, 2, 3, 4, 5, 6, 49, 50, 51, 52, 53, 54, 55, 56],
        [1, 2, 3, 4, 5, 6, 54, 55, 56, 49, 50, 51, 52, 53, 54, 55, 56],
        [1, 2, 53, 54, 55, 44, 45, 46, 47, 48, 49, 50, 51, 52, 54, 55, 56],
        [1, 2, 3, 4, 43, 44, 45, 46, 47, 48, 49, 50, 51, 53, 52, 54, 55, 56],
      ];
      const rowlabel = ["D", "C", "B", "A"];
      for (let j = 0; j < 4; j++) {
        x = 350;
        for (let i = 0; i < 56; i++) {
          if ([9, 21, 35, 47].includes(i) && j != 2 && j != 3) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: rowlabel[j],
              y: j * 15 + xy,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 10;
          }

          if ([9, 21, 35, 38].includes(i) && (j == 2 || j == 3)) {
            zx.push({
              x: (i + 1) * 15 + x + (i == 38 ? +(15 * 9) : 0),
              width: 15,
              height: 15,
              text: rowlabel[j],
              y: j * 15 + xy,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 10 + 15 * (i == 38 ? 9 : 0);
          }
          if (!skip[j].includes(i + 1)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: i + 1,
              id: "Section 200 - Row " + rowlabel[j] + " - Seat " + (i + 1),
              y: j * 15 + xy,
              type: "rect",
              fill: "#73c2fb",
              stroke: "white",
            });
          }
        }
      }

      /// upper body ends

      /// lower body starts
      // cyan bottom 200
      xy = 880;
      const r = ["A", "B", "C", "D"];
      skip = [
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 53, 54, 55, 56],
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 53, 54, 55, 56],
        [1, 2, 3, 4, 5, 6, 7, 51, 52, 53, 54, 55, 56],
        [1, 2, 3, 4, 5, 6, 7, 51, 52, 53, 54, 55, 56],
      ];
      for (let j = 0; j < 4; j++) {
        x = 350;
        for (let i = 0; i < 56; i++) {
          if ([21, 35, 47].includes(56 - i)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: r[j],
              y: j * 15 + xy,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 10;
          }
          if (!skip[j].includes(56 - i)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              id: "Section 400 - Row " + r[j] + " - Seat " + (56 - i),
              height: 15,
              text: 56 - i,
              y: j * 15 + xy,
              type: "rect",
              fill: "#73c2fb",
              stroke: "white",
            });
          }
        }
      }

      // cyan/magenta Top 200
      x = 330;
      xy = 960;
      skip = [
        [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 52, 53, 54, 55, 56, 57, 58, 24, 25, 26, 27,
          28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
        ],
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 52, 53, 54, 55, 56, 57, 58],
        [1, 2, 3, 4, 5, 6, 54, 55, 56, 57, 58],
        [1, 2, 3, 4, 5, 56, 57, 58],
        [1, 2, 3, 4, 57, 58],
        [1, 2],
        [],
      ];
      for (let j = 0; j < 7; j++) {
        x = 350;
        for (let i = 0; i < 58; i++) {
          if ([9, 23, 37, 51].includes(58 - i)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: rowss1[6 - j],
              y: j * 15 + xy,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 10;
          }
          if (!skip[j].includes(58 - i)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: 58 - i,
              y: j * 15 + xy,
              id: "Section 400 - Row " + rowss1[6 - j] + " - Seat " + (58 - i),
              type: "rect",
              fill: "#ff92ff",
              stroke: "white",
            });
          }
        }
      }

      // red bottom 400 seats starts
      x = 350;
      xy = 1080;
      for (let j = 0; j < 13; j++) {
        x = 350;
        for (let i = 0; i < 56; i++) {
          if ([9, 23, 37, 51].includes(58 - i)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: j == 12 ? "Y" : rowss[j],
              y: j * 15 + xy,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 10;
          }
          if (
            j <= 11 ||
            (j == 12 &&
              ![
                10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
                26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
              ].includes(58 - i))
          ) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: 58 - i >= 41 && j == 12 ? 58 - i + 2 : 58 - i,
              y: j * 15 + xy,
              id:
                "Section 400 - Row " +
                (j == 12 ? "Y" : rowss[j]) +
                " - Seat " +
                (58 - i >= 41 && j == 12 ? 58 - i + 2 : 58 - i),
              type: "rect",
              fill: "red",
              stroke: "white",
            });
          }
        }
      }
      // red bottom 400 seats ends

      /*x = 350;
      for (let i = 0; i < 58; i++) {
        if ([9, 23, 37, 51].includes(i)) {
          zx.push({
            x: (i + 1) * 15 + x,
            width: 15,
            height: 15,
            text: "Y",
            y: 0,
            type: "rect",
            fill: "white",
            stroke: "white",
          });
          x += 10;
        }
        if (
          ![
            10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
            27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
          ].includes(i + 1)
        ) {
          zx.push({
            x: (i + 1) * 15 + x,
            width: 15,
            height: 15,
            text: 58 - i,
            y: 1200,
            id: "Section 400 - Row Y - Seat " + (58 - i),
            type: 'rect',
            fill: 'red',
            stroke: 'white'
          });
        }
      }
      */
      // round seating section starts
      const zobj = {
        0: {
          texts: [31, 26, 21, 16, 11, 6, 1],
          x: 130,
          y: 1000,
          count: 7,
        },
        1: {
          texts: [32, 27, 22, 17, 12, 7, 2],
          x: 130,
          y: 1080,
          count: 7,
        },
        2: {
          texts: [33, 28, 23, 18, 13, 8, 3],
          x: 130,
          y: 1160,
          count: 7,
        },
        3: {
          texts: [34, 29, 24, 19, 14, 9, 4],
          x: 130,
          y: 1240,
          count: 7,
        },
        4: {
          texts: [35, 30, 25, 20, 15, 10, 5],
          x: 130,
          y: 1320,
          count: 7,
        },
      };

      for (let j = 0; j < 5; j++) {
        for (let k = 0; k < zobj[j].count; k++) {
          zx.push({
            x: zobj[j].x * (k + 1) + 300,
            y: zobj[j].y - 550,
            val: seat[8],
            distance: 10,
            id: zobj[j].texts[k],
            ydistance: 10,
            text: zobj[j].texts[k],
          });
        }
      }
      // round seating section ends

      this.dict = {};
      let recss = [];
      listdata.forEach((e) => {
        recss = [...recss, ...e.availableSeating];
      });
      recss.forEach((r) => {
        if (r.alias && r.alias.includes("Row")) {
          this.dict[r.alias] = true;
        } else {
          this.dict[r.table.split(" ")[1]] = true;
        }
      });
      console.log(this.dict);
      return [...zx];
    },
    drawseatsd2(listdata) {
      const vm = this;
      vm.dict = {};
      console.log("wqwqwqwqwqw");
      const svg = window.d3
        .select("div#d1")
        .append("svg")
        .attr("width", "1400px")
        .attr("height", "1300px");
      const innerCircleRadius = 15;
      const outerCircleRadius = 15;
      const outerCircleRadius1 = 15;

      this.datad2(listdata).forEach((v) => {
        if (v.type != "rect") {
          const originX = v.x + v.distance;
          const originY = v.y + v.ydistance;
          svg.append("circle").attr({
            cx: originX,
            cy: originY,
            r: innerCircleRadius,
            fill: this.dict[v.id] ? "#4fff00" : "gray",
            stroke: "black",
          });

          svg
            .append("text")
            .attr({
              x:
                originX -
                (Number(v.text) > 9 ? (Number(v.text) > 99 ? 12 : 9) : 3),
              y:
                originY +
                (Number(v.text) > 9 ? (Number(v.text) > 99 ? 5 : 5) : 5),
              "font-size": 15,
            })
            .text(v.text);

          for (let i = 0; i < 1200; i += v.val) {
            const chairOriginX1 =
              originX - 3 + (outerCircleRadius1 + 16) * Math.sin(i);
            const chairOriginY1 =
              originY + 2 - (outerCircleRadius1 + 16) * Math.cos(i);
            const chairOriginX =
              originX + (outerCircleRadius + 5) * Math.sin(i);
            const chairOriginY =
              originY - (outerCircleRadius + 5) * Math.cos(i);

            svg.append("circle").attr({
              cx: i == 1200 ? Math.ceil(chairOriginX + 1) : chairOriginX,
              cy: chairOriginY,
              r: 4,
              fill:
                vm.dict[v.id] || v.fill == "#4fff00" ? "#4fff00" : "lightgrey",
            });

            svg
              .append("text")
              .attr({
                x: chairOriginX1,
                y: chairOriginY1,
                stroke: "black",
                "font-size": 10,
              })
              .text(() => {
                return i > 0 ? Math.ceil(i / v.val) + 1 : 1;
              });
          }
        }

        if (v.type == "rect") {
          svg.append("rect").attr({
            x: v.x,
            y: v.y,
            width: v.width,
            height: v.height,
            stroke: v.stroke,
            fill: vm.dict[v.id] || v.fill == "white" ? v.fill : "lightgrey",
          });

          svg
            .append("text")
            .attr({
              x: v.x + 3,
              y: v.y + 11,
              "font-size": 10,
            })
            .text(v.text);
        }
      });

      svg
        .append("text")
        .attr({
          x: 200,
          y: 100,
          "font-size": 25,
        })
        .text("Section 200");

      svg
        .append("text")
        .attr({
          x: 10,
          y: 320,
          "font-size": 25,
        })
        .text("Section 100");

      svg
        .append("text")
        .attr({
          x: 700,
          y: 1300,
          "font-size": 25,
        })
        .text("Section 400");

      svg.append("rect").attr({
        x: 1260,
        y: 420,
        width: "150px",
        height: "400px",
        "font-size": 25,
        stroke: "black",
        fill: "none",
      });
      svg
        .append("text")
        .attr({
          x: 1300,
          y: 630,
          "font-size": 25,
          stroke: "black",
        })
        .text("Stage");
    },
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal12.close();
    },
    datad1(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        [3, 7, 14, 21, 28, 35, 42],
        [1, 5, 8, 15, 22, 29, 36, 43],
        [9, 16, 23, 30, 37, 44],
        [10, 17, 24, 31, 38],
        [11, 18, 25, 32, 39, 45],
        [2, 6, 12, 19, 26, 33, 40, 46],
        [4, 13, 20, 27, 34, 41, 47],
      ];
      const ydistances = [30, 0, 200, 220, 200, 0, 30];
      const nodes = [];
      for (let y = 1; y <= texts.length; y++) {
        for (let i = 1; i <= texts[y - 1].length; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 70 * y,
              y: 70 * i + ydistances[y - 1],
              val: seat[10],
              distance: y * 10,
              ydistance: i * 10,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
            });
          }
        }
      }

      let recs = [];
      this.dict = {};
      listdata.forEach((r) => {
        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord - 1));
      });
      return nodes;
    },
    drawseatsd1(listdata) {
      const vm = this;
      const svg = window.d3
        .select("div#d1")
        .append("svg")
        .attr("width", "600px")
        .attr("height", "750px");
      const innerCircleRadius = 15;
      const outerCircleRadius = 15;
      const outerCircleRadius1 = 15;
      this.datad1(listdata).forEach((v) => {
        const originX = v.x + v.distance;
        const originY = v.y + v.ydistance;
        svg.append("circle").attr({
          cx: originX,
          cy: originY,
          r: innerCircleRadius,
          fill: v.selected ? "yellow" : "none",
          stroke: "black",
        });

        svg
          .append("text")
          .attr({
            x:
              originX -
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 12 : 9) : 3),
            y:
              originY +
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 5 : 5) : 5),
            "font-size": 15,
          })
          .text(v.text);

        for (let i = 0; i < 1200; i += v.val) {
          const chairOriginX1 =
            originX - 3 + (outerCircleRadius1 + 16) * Math.sin(i);
          const chairOriginY1 =
            originY + 2 - (outerCircleRadius1 + 16) * Math.cos(i);
          const chairOriginX = originX + (outerCircleRadius + 5) * Math.sin(i);
          const chairOriginY = originY - (outerCircleRadius + 5) * Math.cos(i);

          svg.append("circle").attr({
            cx: i == 1200 ? Math.ceil(chairOriginX + 1) : chairOriginX,
            cy: chairOriginY,
            r: 4,
            fill:
              vm.dict[v.text] &&
              vm.dict[v.text].includes(i > 0 ? Math.ceil(i / v.val) : 0)
                ? "#4FFF00"
                : "lightgrey",
          });

          svg
            .append("text")
            .attr({
              x: chairOriginX1,
              y: chairOriginY1,
              stroke: "black",
              "font-size": 10,
            })
            .text(() => {
              return i > 0 ? Math.ceil(i / v.val) + 1 : 1;
            });
        }
      });

      /// rects
      svg
        .append("rect")
        .attr("width", 50)
        .attr("height", 40)
        .attr("x", 290)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 296.5)
        .attr("y", 25)
        .text("Stage")
        .attr("fill", "none")
        .attr("stroke", "black");

      svg
        .append("rect")
        .attr("width", 230)
        .attr("height", 150)
        .attr("x", 200)
        .attr("y", 60)
        .attr("fill", "none")
        .attr("stroke", "black");
    },
    listtopush(rr) {
      this.selectionList = [...rr];
    },
    uniq(list) {
      if (list && list.length > 0) {
        const uniqValues = {};
        list.forEach((v) => {
          uniqValues[v.table] = true;
        });

        return Object.keys(uniqValues);
      }

      return [];
    },
    returnColor(v) {
      const vm = this;
      if (vm.dictlist[v]) {
        if (vm.dictlist[v] == "#ffff00") {
          return "#ffff00";
        } else {
          return vm.dictlist[v];
        }
      } else {
        return "lightgrey";
      }
    },
    individualTick(list) {
      return list.filter((t) => this.individualsData.includes(t.ticketCode));
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.fullname = "";
      this.phoneNo = "";
      this.bname = "";
      this.email = "";
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      this.redeemData = null;
      this.selectionList = [];
      this.redeemPriceId = "";
      this.selected = idx;
      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0] &&
        this.TicketsResult[idx].ticketCode != "tk_pX0Lp69INGHCNiw"
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].table;
      } else {
        this.availSeating = "";
      }
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }

      let availableSeatsRecords =
        this.TicketsResult[this.selected].availableSeating;
      let mapper = {};
      availableSeatsRecords.forEach((tick) => {
        if (!mapper[tick.table]) {
          mapper[tick.table] = {};
        }
        if (!mapper[tick.table][tick.row]) {
          mapper[tick.table][tick.row] = [];
        }
        mapper[tick.table][tick.row].push(tick);
      });

      this.mapper = mapper;

      this.selectedSection = Object.keys(this.mapper)[0];
      this.selectedRow = Object.keys(this.mapper[this.selectedSection])[0];
      this.rowChange();

      setTimeout(() => {
        this.$refs.modal.open();
      }, 100);
    },
    paynow: function () {
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (
        vm.availSeating.length > 0 &&
        ![
          "tk_rZSBy6XYB0mi1gD",
          "tk_6nrKeDNABJpl2Z8",
          "tk_c50x9bsGTpPFcUO",
          "tk_VCPLvbavEi9uAok",
        ].includes(this.TicketsResult[vm.selected].ticketCode)
      ) {
        const seatsArr = [];
        const initalval = this.availSeating;
        seatsArr.push(initalval + " - Seat 1");
        seatsArr.push(initalval + " - Seat 2");
        seatsArr.push(initalval + " - Seat 3");
        seatsArr.push(initalval + " - Seat 4");
        seatsArr.push(initalval + " - Seat 5");
        seatsArr.push(initalval + " - Seat 6");
        seatsArr.push(initalval + " - Seat 7");
        seatsArr.push(initalval + " - Seat 8");
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: seatsArr,
        };
      }

      if (
        [
          "tk_rZSBy6XYB0mi1gD",
          "tk_6nrKeDNABJpl2Z8",
          "tk_c50x9bsGTpPFcUO",
          "tk_VCPLvbavEi9uAok",
        ].includes(this.TicketsResult[vm.selected].ticketCode)
      ) {
        const seatRecord = [];
        this.selectionList.forEach((r) => {
          seatRecord.push(r.alias);
        });
        payloadToPush.totalTickets = seatRecord.length;
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: seatRecord,
        };
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "sKrgo7tG6lcDKN",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_rZSBy6XYB0mi1gD: null,
            tk_6nrKeDNABJpl2Z8: null,
            tk_c50x9bsGTpPFcUO: null,
            tk_VCPLvbavEi9uAok: null,
            tk_UOsXamYXUXquI5n: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
          // this.drawseatsd1(vm.eventTicketResult);
          setTimeout(() => {
            vm.drawseatsd2(vm.eventTicketResult);
          }, 1000);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  mounted() {
    this.fetchAllTickets();
    if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.showsuccess = true;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.showsuccess = true;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phoneNo || "").match(/\d/g) &&
        (this.phoneNo || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title:
      "MYLE - TU 100th Homecoming Alumni Concert & Fundraiser starring KEM",
    meta: [
      {
        name: "description",
        content:
          "MYLE - TU 100th Homecoming Alumni Concert & Fundraiser starring KEM",
      },

      {
        name: "keywords",
        content:
          "MYLE - TU 100th Homecoming Alumni Concert & Fundraiser starring KEM",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/tu-100th-homecoming-alumni-concert-fundraiser-starring-kem",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

#d1 {
  overflow-x: scroll;
}

.cred {
  box-shadow: 4px 4px #ff0000;
}

.credg {
  box-shadow: 4px 4px #ff0000;
}

.cmagenta {
  box-shadow: 4px 4px #ff92ff;
}

.cblue {
  box-shadow: 4px 4px #73c2fb;
}

.cgreen {
  box-shadow: 4px 4px #4fff00;
}

.bolded {
  font-weight: bold;
}

.shimmer {
  display: inline-block;
  mask: linear-gradient(-60deg, #000 30%, rgba(179, 255, 0, 0.333), #000 70%)
    right/350% 100%;
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  100% {
    mask-position: left;
  }
}
</style>
